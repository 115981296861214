import React, {useState} from "react";
import {Box, Button, Flex} from "theme-ui";
import {navigate} from "gatsby";
import Search from "@mui/icons-material/Search";
import LoadingIndicator from "./LoadingIndicator";

const SearchListingsButton = () => {
  const [isLoading, setLoading] = useState(false);

  return <Box sx={{ transition: "0.3s all", }}>
    <Button disabled={isLoading} variant="primary" onClick={() => {
      console.info("find listings...");
      setLoading(true);
      navigate('/listings');
    }} >
      <Flex sx={{ flexDirection: "row", alignItems: "center" }}>
        <Search />
        &nbsp;Find Listings
      </Flex>
    </Button>
    { isLoading && <LoadingIndicator /> }
  </Box>;
};

export default SearchListingsButton;
