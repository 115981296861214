/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Text} from "theme-ui";
import Seo from "../components/seo";
import Layout from "../components/Layout";
import SearchListingsButton from "../components/SearchListingsButton";
import AddListingButton from "../components/AddListingButton";
export const Head = () => React.createElement(Seo, {
  title: "Home"
});
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Welcome to Freshly Shopped"), "\n", React.createElement(Text, {
    sx: {
      fontSize: 3
    }
  }, React.createElement(_components.p, null, "We all need to ", React.createElement(Text, {
    sx: {
      fontWeight: 'bold'
    }
  }, "shop"), ". Better ", React.createElement(Text, {
    sx: {
      fontWeight: 'bold'
    }
  }, "shop together"), ".")), "\n", React.createElement("br"), "\n", React.createElement(SearchListingsButton), "\n", React.createElement(Text, {
    sx: {
      fontSize: 3
    }
  }, React.createElement(_components.p, null, "Find a special, so you can ", React.createElement(Text, {
    sx: {
      fontWeight: 'bold'
    }
  }, "save"), ".")), "\n", React.createElement("br"), "\n", React.createElement(AddListingButton), "\n", React.createElement(Text, {
    sx: {
      fontSize: 3
    }
  }, React.createElement(_components.p, null, React.createElement(Text, {
    sx: {
      fontWeight: 'bold'
    }
  }, "Share"), " specials, so others can save, too.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
